<template>
  <div class="container mt-5 mb-5">
    <div class="row justify-content-center align-items-center">
      <div class="col-md-6 col-sm-12 col-lg-4">
        <div class="card main-card">
          <div class="card-body">
            <form @submit.prevent="sendRecovery">
              <div class="text-center">
                <img src="@/assets/logo.png" width="133">
              </div>
              <h5 class="text-center">Password recovery</h5>
              <div class="mb-3">
                <label for="username" class="form-label text-start">Username</label>
                <input type="text" class="form-control" placeholder="user@agtl.ink" v-model="username" required>
              </div>
              <vs-button type="submit" class="mt-3" block>
                Send Recovery Mail
              </vs-button>
              <!-- <button type="submit" class="btn btn-primary w-100"><span class="spanButton">Send Recovery Mail</span></button> -->
              <hr>
              <p class="text-center smallText mt-3">
                Already have an account? <router-link to="/">Sign in</router-link>
              </p>
              <p class="text-center smallText mt-3">
                <router-link class="text-link" to="/register">Sign up</router-link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { sendPasswordResetEmail } from "firebase/auth";
  import { auth } from "@/fb";

  export default {
    name: 'Forgot',
    data() {
      return {
        username: '',
      };
    },
    methods: {
      sendRecovery() {
        if (!this.vEmail(this.username)) {
          this.notify('error', 'Invalid email format');
          return;
        }

        this.openLoading();

        sendPasswordResetEmail(auth, this.username)
          .then(() => {
            this.notify('success', 'Password reset email sent');
          })
          .catch((error) => {
            this.notify('error', `Error: ${error.code}`);
          })
          .finally(() => {
            this.username = null;
            this.closeLoading();
          });
      },
    },
  }
</script>